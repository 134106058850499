










































import { defineComponent } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "./RoleUserTable.vue";
import { tableHeadersUsersInfoModal } from "@monorepo/utils/src/variables/projectsData/systemAccesses/tableHeadersUsersInfoModal";
import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";
import { cloneDeep } from "lodash";
import { IRoleAPI } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { IShortUserInfoForRoleModal } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IShortUserInfoForRoleModal";
import { getRemoveUsersIdsFromRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/getRemoveUsersIdsFromRole";
import { convertRoleUsersToShortForm } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/convertRoleUsersToShortForm";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

interface IData {
  tableHeadersUsersInfoModal: typeof tableHeadersUsersInfoModal;
  users: IShortUserInfoForRoleModal[];
  localUsers: IShortUserInfoForRoleModal[];
  infiniteId: string;
  isLoading: boolean;
}

export default defineComponent({
  name: "UsersListInfoModal",
  components: {
    ScrollPanel,
    TableHeader,
    InfiniteScroll,
    Table,
  },
  data(): IData {
    return {
      tableHeadersUsersInfoModal,
      users: [],
      localUsers: [],
      infiniteId: uuid(),
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          infiniteId: string;
          getRoleUserList: (id: number) => void;
        },
        value: number
      ) {
        if (value) {
          await this.addQueryOpenedId();
          this.infiniteId = uuid();
        }
      },
    },
  },
  computed: {
    ...mapGetters("systemAccessesView", ["openedId", "roles"]),
    ...mapGetters("auth", ["user", "isShowAnimation"]),
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_MODIFY);
    },
    roleName(): string {
      return (this.roles || []).find((role: IRoleAPI) => +role.id === +this.openedId)?.name ?? "";
    },
  },
  methods: {
    ...mapActions("systemAccessesView", ["getRoleUsers", "deleteUserFromRole", "getRoles"]),
    addQueryOpenedId() {
      return this.$router.replace({ query: { ...(this.$route.query || {}), card: this.openedId } }).catch(console.warn);
    },
    async getRoleUserList() {
      this.isLoading = true;
      const params = {
        id: this.openedId,
        offset: this.users.length,
      };
      const { data, totalLength }: { data: IUser[]; totalLength: number } = await this.getRoleUsers(params)
        .catch(() => ({
          data: [],
          totalLength: 0,
        }))
        .finally(() => {
          this.isLoading = false;
        });
      const users: IShortUserInfoForRoleModal[] = convertRoleUsersToShortForm(data, this.openedId);
      this.users = this.users.concat(cloneDeep(users));
      this.localUsers = this.localUsers.concat(cloneDeep(users));
      return { data: data.length ? data : null, totalLength };
    },
    removeRole(id: string) {
      this.localUsers = this.localUsers?.filter((item) => item.id !== id) ?? [];
    },
    async save() {
      if (this.localUsers.length !== this.users.length) {
        const removeIds = getRemoveUsersIdsFromRole(this.localUsers, this.users);
        const promises = removeIds.map((id) => this.deleteUserFromRole({ roleId: this.openedId, userId: id }));
        await Promise.all(promises).catch(() => {
          showNotification("Ошибка отзыва роли у пользователей.");
        });
        await this.getRoles();
      }
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
      const query = Object.assign({}, this.$route.query);
      delete query.card;
      this.$router.push({ query: { ...query } });
    },
  },
});
