import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { IChapter } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";

export interface IAuthoritiesGroup {
  id: string;
  icon: string;
  title: string;
  isHeader: boolean;
  rolesList: IChapter[];
  authorityGroupIds: string[];
}

export const authoritiesGroups: Partial<IAuthoritiesGroup>[] = [
  {
    id: "expertReviewCommission",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/comission.svg`,
    title: "ЭПК",
    authorityGroupIds: ["EXPERT_COMMISSION", "EXPERT_COMMISSION_APPROVER", "EXPERT_COMMISSION_COORDINATOR", "EXPERT_COMMISSION_CREATION"],
  },
  {
    id: "inventory",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/eventLog.svg`,
    title: "Учёт",
    authorityGroupIds: [
      "INVENTORY",
      "CASE",
      "EAD",
      "CASE_ACT",
      "TK_DOCUMENT",
      "TK_DICTIONARY",
      "TK_OTHER",
      "exceptions43",
      "exceptions42",
      "AK",
      "OIK_CASE_TRANSFER",
      "ITRA",
      "NOMENCLATURE",
    ],
  },
  {
    id: "catalog",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/libraries.svg`,
    title: "Справочники",
    authorityGroupIds: ["OIK", "ARCHIVE", "FUND", "SERVICE_DICTIONARY", "OIK_LOG_UPDATE", "DOCUMENT_KIND"],
  },
  {
    id: "reports",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/report.svg`,
    title: "Отчёты",
    authorityGroupIds: ["exceptions99", "REPORT", "SCHEDULED_REPORT", "REPORT_GENERATED"],
  },
  {
    id: "monitoring",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/monitoring.svg`,
    title: "Мониторинг",
    authorityGroupIds: ["MONITORING", "TK_JOURNAL", "OIK_LOG", "EVENT_LOG"],
  },
  {
    id: "administration",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/administration.svg`,
    title: "Администрирование",
    authorityGroupIds: [
      "exceptions114",
      "SETUP",
      "exceptions49",
      "exceptions72",
      "exceptions51",
      "exceptions52",
      "exceptions50",
      "exceptions46",
      "exceptions47",
      "exceptions48",
      "exceptions53",
      "exceptions110",
      "NOTIFICATION",
      "ADMINISTRATION",
      "ERROR_MSG",
      "DATA_STORAGE_CHED",
      "exceptions80",
      "GAS_NSI",
    ],
  },
  {
    id: "informationSecurity",
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/informationSecurityItem.svg`,
    title: "ИБ",
    authorityGroupIds: ["USER", "exceptions8", "exceptions9", "ROLE", "IS_EVENT_LOG"],
  },
];
