

























































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/systemAccesses/roleTableHeaders";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemAccesses/viewTitle";
import { convertRolesFromApi } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/convertingRolesApi";
import { IChapter, IRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authoritiesGroups, IAuthoritiesGroup } from "@monorepo/informationSecurity/src/views/SystemAccesses/constants/authoritiesGroups";

export default defineComponent({
  components: {
    OpenCardButton,
    IdCell,
  },
  props: {
    roleId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders,
      viewUniqKey,
      selectedKey: "id",
    };
  },
  computed: {
    ...mapGetters("systemAccessesView", ["localRoles", "sort", "isSelectAll", "selectedIds", "openedId"]),
    ...mapGetters("systemAccessesView", { getCells: "cells" }),
    ...mapGetters("auth", ["user"]),
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_MODIFY);
    },
    resultData(): IRole {
      return convertRolesFromApi(this.localRoles)[this.roleId];
    },
    resultGroupedData(): (IAuthoritiesGroup | IChapter)[] {
      const rolesObj =
        this.resultData.chapters?.reduce((acc: Record<string, IChapter>, curr: IChapter) => {
          acc[curr.id] = curr;
          return acc;
        }, {}) || {};
      const resArr: IAuthoritiesGroup[] = authoritiesGroups.map((roleObj: Partial<IAuthoritiesGroup>) => ({
        id: roleObj.id || "",
        icon: roleObj.icon || "",
        title: roleObj.title || "",
        authorityGroupIds: roleObj.authorityGroupIds || [],
        isHeader: true,
        rolesList: roleObj.authorityGroupIds?.map((id: string) => rolesObj[id] || {}) || [],
      }));
      return resArr.reduce((acc: (IAuthoritiesGroup | IChapter)[], curr: IAuthoritiesGroup) => {
        return acc.concat(curr).concat(curr.rolesList || []);
      }, []);
    },
  },
  methods: {
    ...mapActions("systemAccessesView", ["addOpenedId", "setSelectedIds", "setIsSelectAll"]),
  },
});
