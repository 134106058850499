



























































import { defineComponent, ref } from "@vue/composition-api";
import mask from "@monorepo/utils/src/directives/mask";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import FooterDefault from "@monorepo/uikit/src/components/tableViews/Footer.vue";
import { v4 as uuid } from "uuid";
import { emptyRoleKey } from "@monorepo/informationSecurity/src/views/SystemAccesses/constants/emptyRoleKey";
import { convertRolesFromApi, convertRolesToApi } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/convertingRolesApi";
import { convertRolesToApi as convertRolesToApiToSave } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/convertRolesToApi";
import { cloneDeep, isEqual } from "lodash";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { mapActions, mapGetters } from "vuex";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/systemAccesses/roleTableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemAccesses/viewTitle";
import { IChapter, IRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { formAuthorities } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/formAuthorities";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import SystemAccessesRoleInfoModal from "./SystemAccessesRoleInfoModal.vue";
import RoleTable from "./RoleTable.vue";
import { changeRight } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/changeRight";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "Roles",
  directives: {
    mask,
  },
  props: {
    openedRole: {
      type: String,
      default: "",
    },
  },
  components: {
    FooterDefault,
    Footer,
    SystemAccessesRoleInfoModal,
    ExportButton,
    ScrollPanel,
    TableHeader,
    RoleTable,
  },
  data() {
    return {
      tableHeaders,
      viewTitle,
      viewUniqKey,
      educationIds,
      maskValue: { regex: "^[0-9a-zA-Zа-яА-Я№][0-9a-zA-Zа-яА-Я-_№ ]*", placeholder: "" },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("systemAccessesView", ["roles", "totalLocalRoles", "authorities", "isTableLoading"]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_EXPORT);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_DELETE);
    },
    isShowFooter(): boolean {
      return !isEqual(convertRolesFromApi(this.roles), this.localRoles);
    },
    openedPanels(): number[] {
      return [Object.keys(convertRolesFromApi(this.roles)).findIndex((roleId: string) => +roleId === +this.openedRole)];
    },
    localRoles: {
      get(): { [key: string]: Partial<IRole> } {
        return convertRolesFromApi(this.$store.getters["systemAccessesView/localRoles"]);
      },
      set(value: { [key: string]: Partial<IRole> }) {
        this.$store.commit("systemAccessesView/setLocalRoles", {
          data: convertRolesToApi({
            ...value,
          }),
          total: this.totalLocalRoles > Object.keys(value)?.length ? this.totalLocalRoles : Object.keys(value)?.length,
        });
      },
    },
  },
  methods: {
    ...mapActions("systemAccessesView", ["addOpenedId", "saveRoles", "deleteRoleQuery"]),
    isNeedSave: (key: string) => key.includes(emptyRoleKey),
    addNewRole() {
      this.localRoles = {
        ...this.localRoles,
        [emptyRoleKey + " " + uuid()]: {
          name: "",
          users: 0,
          code: "",
          chapters: formAuthorities(this.authorities, {}) as unknown as IChapter[],
        },
      };
    },
    changeRight(roleId: string, chapterId: string, right: string, currentValue: boolean) {
      const roles = cloneDeep(this.localRoles);
      if (roles?.[roleId]?.chapters) {
        roles[roleId].chapters = changeRight(roles?.[roleId]?.chapters || [], chapterId, currentValue, right);
      }
      this.localRoles = roles;
    },
    addNewName(value: string, key: string) {
      const roles = cloneDeep(this.localRoles);
      (roles[key] as { name: string }).name = value;
      this.localRoles = roles;
    },
    deleteRole(key: string) {
      const roles = cloneDeep(this.localRoles);
      delete roles[key];
      this.localRoles = roles;
    },
    getExportData() {
      return;
    },
    cancelChanges() {
      this.localRoles = cloneDeep(this.roles);
    },
    async save() {
      if (Object.values(this.localRoles).some((item) => !item.name)) {
        showNotification("Ошибка сохранения ролей: введите название роли");
        return;
      }
      if (!this.roles.every((role: IRole) => this.localRoles[role.id])) {
        const deleteIds: string[] = this.roles.filter((role: IRole) => !this.localRoles[role.id]).map((role: IRole) => role.id);
        await Promise.all(deleteIds.map((id: string) => this.deleteRoleQuery(id))).catch(console.error);
      }
      const roles = convertRolesToApiToSave(this.localRoles, this.roles);
      this.saveRoles(roles);
      return;
    },
    openTmpModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    toggleModal(value: boolean) {
      this.showModal = value;
      if (!value) {
        this.$store.dispatch("systemAccessesView/addOpenedId", null);
      }
    },
    openModal(id: string) {
      this.addOpenedId(id);
      this.toggleModal(true);
    },
    openForbiddenModal(roleId: string, userQty: number) {
      this.addOpenedId(roleId);
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ForbiddenDeleteRoleModal", { roleId, userQty }));
    },
  },
  beforeMount() {
    eventBus.$on("OPEN_ROLE_MODAL", this.toggleModal);
  },
  beforeDestroy() {
    eventBus.$off("OPEN_ROLE_MODAL", this.toggleModal);
  },
  setup(props, { root }) {
    const showModal = ref(false);

    useSetStartModal(root, "systemAccessesView");

    if (root.$store.getters["systemAccessesView/openedId"]) {
      showModal.value = true;
    }

    root.$store.dispatch("systemAccessesView/getRoles").catch(console.error);

    return {
      showModal,
    };
  },
});
