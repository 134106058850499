import { IShortUserInfoForRoleModal } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IShortUserInfoForRoleModal";

export const getRemoveUsersIdsFromRole = (
  localUsers: IShortUserInfoForRoleModal[],
  usersFromApi: IShortUserInfoForRoleModal[]
): (string | number)[] => {
  const resultIds =
    (localUsers || []).filter((item) => item.id).reduce((result: { [key: string]: boolean }, item) => ({ ...result, [item.id]: true }), {}) || {};
  return usersFromApi.filter((item) => !resultIds[item.id]).map((item) => item.id);
};
