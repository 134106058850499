import { IChapter } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";

export const changeRight = (chapters: IChapter[], chapterId: string, currentValue: boolean, right: string): IChapter[] => {
  return (chapters || []).map((chapter: IChapter) => {
    if (chapter.id === chapterId) {
      (chapter[right as keyof IChapter] as unknown) = !currentValue;
      return chapter;
    }
    if (chapter.chapters && chapter.chapters.length) {
      chapter.chapters = changeRight(chapter.chapters, chapterId, currentValue, right);
    }
    return chapter;
  });
};
