import { IRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";

export const convertRolesFromApi = (roles: IRole[]): { [key: string]: IRole } => {
  const convertedRoles: { [key: string]: IRole } = {};
  (roles || []).forEach((item) => {
    convertedRoles[item.id] = {
      ...item,
    };
  });
  return convertedRoles;
};

export const convertRolesToApi = (roles: { [key: string]: unknown }): IRole[] => {
  return Object.entries(roles || {}).map(([key, value]) => ({
    id: key,
    ...(value as Omit<IRole, "id">),
  }));
};
