import { v4 as uuid } from "uuid";

export const tableHeaders = [
  { text: "Раздел", width: 444, value: "title", isLink: true, isSorted: false, isHandle: true, id: uuid() },
  { text: "Доступ", width: 133, value: "isViewing", isLink: true, isSorted: false, isHandle: true, id: uuid() },
  { text: "Создание", value: "isCreate", width: 133, isSorted: false, isHandle: false, id: uuid() },
  { text: "Редактирование", value: "isEdit", width: 133, isSorted: false, isHandle: false, id: uuid() },
  { text: "Удаление", value: "isDelete", width: 133, isSorted: false, isHandle: false, id: uuid() },
  { text: "Экспорт", value: "isExport", isSorted: false, isHandle: false, id: uuid() },
];
