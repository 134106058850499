


























import { defineComponent, ComponentInstance, computed } from "@vue/composition-api";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { mapGetters } from "vuex";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { IShortUserInfoForRoleModal } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IShortUserInfoForRoleModal";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useTable from "@monorepo/utils/src/composables/useTable";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import useTableAnimation from "@monorepo/utils/src/composables/useTableAnimation";

export default defineComponent({
  components: {
    OpenCardButton,
  },
  props: {
    tableHeaders: {
      type: Array,
      default() {
        return [];
      },
    },
    roleUsers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("systemAccessesView", ["users", "selectedIds", "isSelectAll", "openedUserId"]),
    ...mapGetters("systemAccessesView", { data: "users" }),
    ...mapGetters("auth", ["user"]),
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.ROLE_DELETE);
    },
    resultData(): unknown[] {
      return this.roleUsers as unknown[];
    },
  },
  methods: {
    openUser(headerValue: string, event: IShortUserInfoForRoleModal) {
      if (headerValue === "grantedDate") {
        return;
      }

      this.$router.replace({
        name: "systemAccesses",
        params: { tab: "users" },
        query: { card: `${headerValue === "login" ? event.id : event.userId}` },
      });
    },
    onScrollCb(e: Event) {
      console.log(e);
    },
    removeRole(id: string) {
      this.$emit("removeRole", id);
      return;
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const tableHeaders = computed(() => props.tableHeaders as unknown[]);
    const { clickCheckbox, isElementDeleted, selectedIdsElements } = useTable(root, props.sectionName as string);
    const { headers } = useTableCells(root, props.sectionName as string, tableHeaders, 702);
    const { isShowAnimation, clearAnimation, addAnimation } = useTableAnimation(root);
    return {
      clickCheckbox,
      isElementDeleted,
      selectedIdsElements,
      headers,
      isShowAnimation,
      clearAnimation,
      addAnimation,
    };
  },
});
