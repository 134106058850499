import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";
import { IShortUserInfoForRoleModal } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IShortUserInfoForRoleModal";
import moment from "moment";

export const convertRoleUsersToShortForm = (users: IUser[], roleId: number): IShortUserInfoForRoleModal[] => {
  return (users || []).map((user) => {
    const currentRole = user.roles?.find((role) => +role.id === +roleId);
    return {
      login: user.username,
      id: user.id,
      grantedDate: currentRole?.grantedDate ? moment(currentRole.grantedDate).format("YYYY-MM-DD") : "",
      username: currentRole?.grantedBy?.username ?? "",
      userId: currentRole?.grantedBy?.id ?? 0,
    };
  });
};
